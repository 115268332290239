import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useApplicationEdit() {
  // Use toast
  const toast = useToast()

  const updateApplicationStatus = (ID, queryParams) => axios
    .post(`/auth/application-status/${ID}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Application Status was successfully changed',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error change Application Status',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))
  const editCamperApplication = (ID, queryParams) => axios
    .put(`/auth/applications/${ID}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Edit Camper Application was successfully created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error posting Camper Application',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const editStaffApplication = (ID, queryParams) => axios
    .put(`/auth/applications/${ID}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Edit Staff Application was successfully created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error posting Staff Application',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchApplication = ID => axios
    .get(`/auth/applications/${ID}/edit`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching application item',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchProgram = ID => axios
    .get(`/auth/programs/${ID}`)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching program item',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const fetchForm = ID => axios
    .get(`/auth/forms/${ID}`)
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching form',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const fetchFile = filePath => axios
    .get('/auth/file/download', {
      params: { file_path: filePath },
      responseType: 'blob',
    })
    .then(response => response.data)
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error downloading file',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const uploadFile = (ID, file) => axios
    .post(`/auth/file/upload/${ID}`, file, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => response.data)
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error uploading file',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const removeFile = queryParams => axios
    .post('/auth/file/remove', queryParams)
    .then(response => response.data)
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error removing file',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const fetchApplicationGroupOptions = (ID, queryParams) => axios
    .post(`auth/applications/${ID}/groups`, queryParams)
    .then(response => response.data.data)
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error getting available groups for application',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const fetchSessionForSelectByApplication = (ID, queryParams) => axios
    .post(`auth/applications/${ID}/sessions`, queryParams)
    .then(response => response.data.data)
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error getting sessions',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'Accepted') return 'success'
    if (status === 'Rejected') return 'danger'
    return 'primary'
  }

  return {
    updateApplicationStatus,
    editCamperApplication,
    editStaffApplication,
    fetchApplication,
    fetchProgram,
    fetchForm,
    fetchFile,
    uploadFile,
    removeFile,
    resolveStatusVariant,
    fetchApplicationGroupOptions,
    fetchSessionForSelectByApplication,
  }
}
