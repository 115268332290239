<template>
  <div
    class="d-flex justify-content-start align-items-center mb-1"
  >
    <b-card-text
      v-if="fieldsData.type === 'step'"
    >
      <h2 class="mt-2">
        {{ fieldsData.label }}
      </h2>
    </b-card-text>

    <b-card-text
      v-else-if="fieldsData.type === 'plain_text'"
      v-html="fieldsData.label"
    />

    <template
      v-else
    >

      <!-- Label -->
      <b-col
        md="3"
        xl="3"
      >
        <b-card-text>
          <h5>
            {{ fieldsData.label }}
          </h5>
        </b-card-text>
      </b-col>

      <!-- Input Name -->
      <b-col
        md="6"
        xl="6"
      >
        <div
          v-if="fieldsData.disabled"
          class="d-flex align-items-center text-break"
        >
          <template
            v-if="fieldsData.type === 'file_upload'"
          >
            <div
              v-if="fieldsData.disabled"
              class="d-flex"
            >
              <div v-if="fieldsData.value">
                <div
                  v-for="(file, index) in fieldsData.value"
                  :key="index"
                >
                  {{ file.name }}
                </div>
              </div>

              <div
                v-else-if="!fieldsData.value && fieldsData.files && fieldsData.files.length === 0 || fieldsData.files === null"
              >
                No files
              </div>

              <div v-else>
                <div
                  v-for="(file, index) in fieldsData.files"
                  :key="index"
                  class="d-flex align-items-center"
                >
                  <feather-icon
                    icon="FileIcon"
                    size="21"
                  />
                  <span class="text-truncate">File {{ index + 1 }}</span>

                  <b-link
                    :href="file.url"
                    target="_blank"
                  >
                    <feather-icon
                      size="16"
                      icon="ExternalLinkIcon"
                      class="ml-1 d-inline-flex"
                    />
                  </b-link>
                </div>
              </div>
            </div>
          </template>

          <template v-else-if="fieldsData.type === 'group_selection'">
            <span v-if="!fieldsData.value">No groups</span>
            <span v-else>{{ groupsNames }}</span>
          </template>

          <template v-else-if="fieldsData.type === 'session_selection'">
            <span v-if="!fieldsData.value">No groups</span>
            <span v-else>{{ sessionsNames }}</span>
          </template>

          <template v-else-if="fieldsData.type === 'signature'">
            <span v-if="!fieldsData.value">No value</span>
            <img
              v-else
              :src="fieldsData.value"
            >
          </template>

          <template v-else-if="fieldsData.type === 'payment_field'">
            <span v-if="!fieldsData.value">No value</span>
            <span v-else>
              <div
                class="d-flex flex-column"
              >
                <span
                  class="text-truncate"
                >${{ fieldsData.value.charge_amount }}</span>
                <span
                  class="text-truncate"
                >Payment plan activated: {{ fieldsData.value.payment_plan_activated ? 'Yes' : 'No' }}</span>
                <span
                  v-if="fieldsData.value.payment_plan_activated"
                  class="text-truncate"
                >Month selected: {{ fieldsData.value.payment_month_selected ? fieldsData.value.payment_month_selected : 1 }}</span>
              </div>
            </span>
          </template>

          <template v-else>
            <span v-if="!fieldsData.value && fieldsData.type !== 'file_upload'">No value</span>
            <span v-else>{{ fieldsData.value }}</span>
          </template>

          <span
            v-if="!fieldsData.readonly && showEditBtn(fieldsData)"
            class="text-primary cursor-pointer"
            @click="editField"
          >
            <feather-icon
              size="16"
              icon="Edit2Icon"
              class="ml-1 d-inline-flex"
            /> Edit
          </span>
          <feather-icon
            v-if="fieldsData.type === 'file_upload' && fieldsData.files && fieldsData.files.length > 0 && fieldsData.disabled"
            size="21"
            icon="DownloadIcon"
            class="cursor-pointer ml-1 d-inline-flex text-primary"
            @click="dowloadAllFiles"
          />
        </div>
        <div
          v-else
          class="d-flex align-items-center"
        >
          <b-form-input
            v-if="showTextField(fieldsData.type)"
            v-model="fieldsData.value"
            :name="fieldsData.name"
            :type="fieldsData.type"
            :required="fieldsData.required"
            size="sm"
            :disabled="fieldsData.disabled"
          />

          <form-result-edit-group-row
            v-if="fieldsData.type === 'group_selection'"
            :fields-data="fieldsData"
            :is-disabled-field="fieldsData.disabled"
          />

          <form-result-edit-session-row
            v-if="fieldsData.type === 'session_selection'"
            :fields-data="fieldsData"
            :is-disabled-field="fieldsData.disabled"
          />

          <b-form-textarea
            v-if="fieldsData.type === 'long_text'"
            id="textarea-default"
            v-model="fieldsData.value"
            :name="fieldsData.name"
            :required="fieldsData.required"
            rows="3"
            :disabled="fieldsData.disabled"
          />

          <b-form-datepicker
            v-if="fieldsData.type === 'date'"
            id="example-datepicker"
            v-model="fieldsData.value"
            :required="fieldsData.required"
            :disabled="fieldsData.disabled"
          />

          <b-form-select
            v-if="fieldsData.type === 'dropdown'"
            v-model="fieldsData.value"
            :options="fieldsData.options"
            :required="fieldsData.required"
            size="sm"
            :disabled="fieldsData.disabled"
          />

          <b-form-select
            v-if="fieldsData.type === 'multi_select'"
            v-model="fieldsData.value"
            :options="fieldsData.options"
            multiple
            :required="fieldsData.required"
            size="sm"
            :disabled="fieldsData.disabled"
          />

          <template
            v-if="fieldsData.type === 'checkbox'"
          >
            <b-form-checkbox-group
              v-model="fieldsData.value"
              :options="fieldsData.options"
              class="py-1"
              :disabled="fieldsData.disabled"
            />
          </template>

          <template
            v-if="fieldsData.type === 'radio_button'"
          >
            <b-form-radio-group
              v-model="fieldsData.value"
              :options="fieldsData.options"
              class="py-1"
              :disabled="fieldsData.disabled"
            />
          </template>

          <template
            v-if="fieldsData.type === 'file_upload'"
          >
            <b-form-file
              v-if="!fieldsData.disabled"
              v-model="fieldsData.value"
              multiple
              :required="fieldsData.required"
              size="sm"
              :file-name-formatter="formatNames"
              :disabled="fieldsData.disabled"
            />
          </template>

          <div
            class="cursor-pointer text-primary d-inline-flex ml-1"
            @click="saveFieldChanges"
          >
            <span>Apply</span>
            <feather-icon
              v-if="!fieldsData.disabled && !fieldsData.readonly"
              size="21"
              icon="CheckIcon"
            />
          </div>
        </div>

      </b-col>

    </template>
  </div>
</template>

<script>
import {
  BCol,
  BCardText,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormRadioGroup,
  BFormFile,
  BLink,
} from 'bootstrap-vue'

import { onUnmounted } from '@vue/composition-api'

import { downloadExportFile } from '@core/mixins/downloadExportFile'

import useProgramsSetting from '@/views/admin/program/useProgramsSetting'
import useApplicationEdit from '@/views/admin/applications/application-edit/useApplicationEdit'

import store from '@/store'
import storeModule from '@/views/admin/group/groupStoreModule'
import FormResultEditGroupRow from '@/views/admin/forms/form-result-edit/FormResultEditGroupRow.vue'
import FormResultEditSessionRow from '@/views/admin/forms/form-result-edit/FormResultEditSessionRow.vue'

export default {
  components: {
    BCol,
    BCardText,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormRadioGroup,
    BFormFile,
    BLink,

    FormResultEditGroupRow,
    FormResultEditSessionRow,
  },
  filters: {
    toString(val) {
      return Array.isArray(val) ? val.join() : val
    },
  },
  mixins: [downloadExportFile],
  props: {
    fieldsData: {
      type: Object,
      default: () => {},
    },
    filesForRemove: {
      type: Array,
      default: () => [],
    },
    fieldIndex: {
      type: Number,
      default: 0,
    },
    canEditApplicationData: {
      type: Boolean,
      default: true,
    },
    canEditApplicationSession: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-program'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      formBuilderFieldTypes,
    } = useProgramsSetting()

    const {
      fetchFile,
    } = useApplicationEdit()
    return {
      fetchFile,
      formBuilderFieldTypes,
    }
  },
  computed: {
    fileNames() {
      return this.fieldsData.files.join(', ')
    },
    groupsNames() {
      return this.fieldsData.value.map(a => a.name).join()
    },
    sessionsNames() {
      return this.fieldsData.value.map(a => a.name).join()
    },
  },
  methods: {
    showTextField(type) {
      // eslint-disable-next-line no-bitwise
      return ~['text', 'email', 'tel', 'number'].findIndex(item => item === type)
    },
    showEditBtn(item) {
      if (this.fieldsData.type === 'session_selection') {
        return this.canEditApplicationSession
      }

      const showFieldsIn = ['payment_field', 'signature']
      return !showFieldsIn.includes(item.type) && this.canEditApplicationData
    },
    formatNames(files) {
      if (files.length === 1) return files[0].name

      return `${files.length} files selected`
    },
    editField() {
      if (this.fieldsData.type === 'session_selection') {
        this.$bvModal.show('edit-sessions')
      } else {
        this.fieldsData.disabled = false
      }
    },
    saveFieldChanges() {
      if (this.fieldsData.type === 'file_upload' && this.fieldsData.files && this.fieldsData.value) {
        this.filesForRemove.push(this.fieldsData.files)
        this.fieldsData.files = []
      }
      if (this.fieldsData.type === 'group_selection' && this.fieldsData.groups) {
        this.fieldsData.value = this.fieldsData.groups
      }
      this.$emit('fieldEdited')
      this.fieldsData.disabled = true
    },
    dowloadAllFiles() {
      this.fieldsData.files.forEach(file => {
        this.dowloadFile(file)
      })
    },
    async dowloadFile(file) {
      await this.fetchFile(file.path).then(response => {
        if (response) {
          const filename = `${file.program_name}/${file.user_name}/${file.name}`
          const fileExt = file.path.split('.').pop()
          this.downloadFile(response, filename.replace(/\s/g, '-'), fileExt)
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style scoped>
  .text-truncate {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
</style>
<style>
  .card-text img {
    max-width: 100%;
  }
</style>
