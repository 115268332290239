<template>
  <v-select
    v-model="value"
    class="w-100 multiple-select"
    placeholder="Select"
    :multiple="!fieldsData.limitSessionSelection"
    :close-on-select="false"
    :options="sessionsList"
    :disabled="isDisabledField"
    label="name"
    :selectable="session => isSessionSelectable(session)"
    :deselect-from-dropdown="true"
    @input="emitChanges"
  >
    <template
      #option="{ name, price }"
      :class="price"
    >
      <span>{{ name }}</span> <span v-if="!hidePrice">${{ price | toFixedNumber }}</span>
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select'
import useApplicationEdit from '@/views/admin/applications/application-edit/useApplicationEdit'

export default {
  components: {
    vSelect,
  },
  filters: {
    toFixedNumber(value) {
      return value.toFixed(2).replace('-', '')
    },
  },
  props: {
    fieldsData: {
      type: Object,
      default: () => {
      },
    },
    isDisabledField: {
      type: Boolean,
      default: false,
    },
    hidePrice: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      fetchSessionForSelectByApplication,
    } = useApplicationEdit()

    return {
      fetchSessionForSelectByApplication,
    }
  },
  data() {
    return {
      value: null,
      sessionsList: [],
      applicationId: this.$router.currentRoute.params.id,
    }
  },
  async mounted() {
    await this.prepareSessionsOptions()
    this.value = this.fieldsData.value
  },
  methods: {
    isSessionSelectable(session) {
      if (session.allowed_individual_selection) {
        return true
      }

      return Array.isArray(this.value) && this.value?.length
    },
    emitChanges() {
      if (!Array.isArray(this.value)) {
        this.value = this.value ? [this.value] : []
      }

      if (this.value.length) {
        const selectableSessionIds = this.value.map(session => session.id)
        const availableIndividualSelection = this.sessionsList
          .filter(session => session.allowed_individual_selection)
          .filter(session => selectableSessionIds.includes(session.id))

        if (!availableIndividualSelection.length) {
          this.value = null
          return
        }
      }

      this.value = this.value.map(session => ({
        id: session.id,
        name: session.name,
      }))
      this.fieldsData.value = this.value
    },
    async prepareSessionsOptions() {
      await this.fetchSessionForSelectByApplication(this.applicationId).then(res => {
        this.sessionsList = res
      })
    },
  },
}
</script>

<style lang="scss">
.group-type-label {
  margin-bottom: 10px;
}

.is-invalid .vs__dropdown-toggle {
  border: 1px solid red;
}
</style>
